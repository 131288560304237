export function groupByCountry(leagues) {
  const data = {}

  leagues.forEach(league => {
    const { name } = league.country

    if (data[name]) {
      data[name].push(league)
    } else {
      data[name] = [league]
    }
  })

  return data
}
