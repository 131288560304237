const BASE = 'https://res.cloudinary.com/smartscore/image/fetch'

export const buildFlagUrl = code =>
  `https://flagcdn.com/${code.toLowerCase()}.svg`

export const buildPopularLeagueUrl = url => `${BASE}/c_limit,q_95,w_50/${url}`

export const buildTeamAndLeagueUrl = (url, width, quality, shadow = false) =>
  `${BASE}/${
    shadow ? 'e_shadow:40,' : ''
  }f_auto,c_limit,w_${width},q_${quality}/${url}`

export const buildPlayerUrl = (apiFootballId, width) =>
  `${BASE}/f_auto,c_limit,w_${width}/https://media.api-sports.io/football/players/${apiFootballId}.png`
