import Star from '@mui/icons-material/Star'
import { IconButton } from '@mui/material'
import { memo } from 'react'

import { useFavorite } from '../filters/stores/favorites'

function _FavoriteMatch({ matchId, slug }) {
  const { isFavorite, setFavorite } = useFavorite(
    { id: matchId, slug },
    'fixture'
  )

  function onFavorite(event) {
    event.stopPropagation()

    setFavorite()
  }

  return (
    <IconButton onClick={onFavorite} size="medium">
      <Star
        color={isFavorite ? 'favorite' : 'action'}
        className={'favorite'}
        sx={{
          transition: 'color ease 0.3s',
          '&:hover': {
            color: theme => theme.palette.favorite.main
          }
        }}
      />
    </IconButton>
  )
}

export const FavoriteMatch = memo(_FavoriteMatch, () => {
  return false
})
