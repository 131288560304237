// eslint-disable-next-line import/no-anonymous-default-export

export const TOP_LEAGUES = new Set([
  // world
  'euro-championship-world',
  'uefa-champions-league-world',
  'uefa-europa-league-world',
  'uefa-europa-conference-league-world',
  'world-cup-qualification-europe-world',

  // England
  'premier-league-england',
  // France
  'ligue-1-france',
  // Germany
  'bundesliga-1-germany',
  // Italy
  'serie-a-italy',
  // Netherlands
  'eredivisie-netherlands',
  // Spain
  'la-liga-spain'
])
