import { ButtonBase, Paper } from '@mui/material'
import { useRef, useLayoutEffect, memo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { lightFormat, eachDayOfInterval } from 'date-fns'
import { useTranslation } from '../../../utils/i18n'
import { Box } from '@mui/system'

import { isCurrentDate, isFutureDate, isPastDate } from '../../../utils/dates'
import { TABS } from '../tabs/tabs'

function generateDates(locale) {
  const today = new Date()

  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  })

  const days = eachDayOfInterval({
    start: new Date().setUTCDate(today.getUTCDate() - 7),
    end: new Date().setUTCDate(today.getUTCDate() + 7)
  })

  return days.map(day => ({
    label: formatter.format(day),
    value: lightFormat(day, 'yyyy-MM-dd')
  }))
}

const toUpperLetter = string =>
  string.replace(string[0], string[0].toUpperCase())

const Day = memo(
  function Day({ label, value, selected, dayRef, query }) {
    const { t } = useTranslation('home', { keyPrefix: 'dates' })
    const [weekDay, rest = ''] = label.split(', ')
    const [month, number] = rest.split(' ')
    const currentDay = isCurrentDate(value)

    // eslint-disable-next-line no-unused-vars
    const { slug, date, tab, ...queries } = query
    const tabQuery = tab

    function getTab() {
      if (+tabQuery === TABS.FAVORITE) return TABS.FAVORITE

      if (currentDay) {
        return TABS.LIVE
      } else if (isFutureDate(value)) {
        return TABS.SCHEDULED
      } else if (isPastDate(value)) {
        return TABS.FINISHED
      } else if (getCurrentTab() !== TABS.FAVORITE) {
        return TABS.ALL
      }
    }

    const href = {
      pathname: '/',
      query: {
        // keep the rest of queries, like `odds`
        ...queries,
        ...(currentDay ? {} : { date: value, tab: getTab() })
      }
    }

    return (
      <Link passHref href={href}>
        <ButtonBase
          className={`${selected && 'active'} ${currentDay && 'today'}`}
          component="a"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '5px',
            minHeight: '65px',
            minWidth: '55px',
            borderRadius: '4px',
            color: 'var(--sm-primary-color) !important',
            fontSize: '0.8rem',
            width: '100%',
            fontWeight: 'bold',
            border: '1px solid var(--sm-primary-color)',
            margin: '0px 8px',
            '&.today': {
              color: 'var(--sm-text-color) !important',
              minWidth: 'auto'
            },
            '&.active': {
              backgroundColor: 'var(--sm-primary-color)',
              color: 'white !important'
            },
            '&:not(.active) span:nth-of-type(2)': {
              color: 'black !important'
            }
          }}
          ref={dayRef}
        >
          {currentDay ? (
            <span>{t('today')}</span>
          ) : (
            <>
              <span
                style={{
                  fontFamily: 'SmartScoreNumbers, sans-serif'
                }}
              >
                {number}
              </span>
              <span>{toUpperLetter(month)}</span>
              <span>{toUpperLetter(weekDay)}</span>
            </>
          )}
        </ButtonBase>
      </Link>
    )
  },
  (prev, next) => (prev.selected ? false : next.selected ? false : true)
)

function useScrollTo() {
  const ref = useRef(null)

  useLayoutEffect(() => {
    const { matches } = window.matchMedia('(max-width: 1365px)')
    if (ref.current && matches) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        inline: 'center',
        block: 'center'
      })
    }
  }, [])

  return ref
}

const getCurrentTab = () => new URLSearchParams(location.search).get('tab')

export const TODAY_SELECTED = lightFormat(new Date(), 'yyyy-MM-dd')

function _Dates() {
  const ref = useScrollTo()
  const { query, locale } = useRouter()
  const dates = useRef(generateDates(locale)).current
  const dayQuery = query.date ?? TODAY_SELECTED

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: '15px',
        width: '100%',
        '@media (max-width: 1365px)': {
          marginTop: '15px'
        }
      }}
    >
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          overflowX: 'auto'
        }}
      >
        {dates.map(date => (
          <Day
            key={date.value}
            label={date.label}
            value={date.value}
            selected={date.value === dayQuery}
            query={query}
            dayRef={date.value === dayQuery ? ref : undefined}
          />
        ))}
      </Box>
    </Paper>
  )
}

export const Dates = memo(_Dates, () => true)
