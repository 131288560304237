import { useMemo } from 'react'

export function useEvents(homeId, awayId, matchEvents = [], eventName) {
  return useMemo(() => {
    const events = matchEvents.filter(({ detail }) => detail == eventName)
    let homeTeamCards = 0
    let awayTeamCards = 0

    events.forEach(card => {
      if (card.team._id === homeId) {
        homeTeamCards++
      } else if (card.team._id === awayId) {
        awayTeamCards++
      }
    })

    return {
      homeTeamEvents: homeTeamCards,
      awayTeamEvents: awayTeamCards
    }
  }, [homeId, awayId, matchEvents, eventName])
}
