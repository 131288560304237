import { Paper } from '@mui/material'
import classNames from '../../../home/leagueTable/league-table.module.css'
import headerClassNames from '../../../home/leagueTable/tableHeader.module.css'
import SmSkeleton from '../Skeleton'
import getRandomSizes from '../getRandomSizes'
import TeamCell from '../shared/TeamCell'
import LastMatches from '../shared/LastMatches'

const SkeletonGroup = ({ width = 20 }) => {
  return (
    <>
      <SmSkeleton style={{ width, margin: 2 }} />
      <SmSkeleton style={{ width, margin: 2 }} />
      <SmSkeleton style={{ width, margin: 2 }} />
    </>
  )
}
function MatchRow({ homeSize, awaySize }) {
  return (
    <div className={`${classNames.row} ${classNames.matchRow}`}>
      <div className={classNames.favorite}>
        <SmSkeleton width={20} height={20} />
      </div>
      <div className={classNames.time}>
        <SmSkeleton width={30} />
      </div>
      <TeamCell
        homeTeamSize={homeSize}
        awayTeamSize={awaySize}
        className={classNames.teams}
      />
      <div className={classNames.form}>
        <SmSkeleton width={27} height={19} />
        <SmSkeleton width={27} height={19} />
      </div>

      <div className={classNames.last5}>
        <LastMatches />
        <LastMatches />
      </div>

      <div className={classNames.odds}>
        <SkeletonGroup />
      </div>
      <div className={classNames.predictions} style={{ flexDirection: 'row' }}>
        <SkeletonGroup width={30} />
      </div>
      <div className={classNames.tip}>
        <SmSkeleton height={35} style={{ width: '100%' }} />
      </div>
    </div>
  )
}

function TableHeaderRow() {
  return (
    <div className={`${classNames.row} ${classNames.headerRow}`}>
      <div className={classNames.favorite}>
        <SmSkeleton width={36} />
      </div>
      <div className={classNames.time}>
        <SmSkeleton width={50} />
      </div>

      <div
        className={classNames.teams}
        style={{ justifyContent: 'flex-start' }}
      >
        <SmSkeleton width={70} />
      </div>

      <div className={classNames.form}>
        <SmSkeleton width={28} />
      </div>

      <div className={classNames.last5}>
        <SmSkeleton width={100} />
      </div>

      <div className={classNames.odds}>
        <SmSkeleton width={40} />
      </div>
      <div className={classNames.predictions}>
        <SmSkeleton width={90} />
      </div>
      <div className={classNames.tip}>
        <SmSkeleton width={40} />
      </div>
    </div>
  )
}

function Header() {
  return (
    <div className={headerClassNames.header}>
      <SmSkeleton width={30} />
      <SmSkeleton className={headerClassNames.skeletonHeaderTitle} />
    </div>
  )
}

function LeagueTableSkeleton() {
  const homeSizes = getRandomSizes(5)
  const awaySizes = getRandomSizes(5).reverse()

  return (
    <Paper className={classNames.table}>
      <Header />
      <TableHeaderRow />
      {[...Array(2)].map((_, index) => (
        <MatchRow
          key={index}
          homeSize={homeSizes[index] * 0.8}
          awaySize={awaySizes[index] * 0.8}
        />
      ))}
    </Paper>
  )
}

export default LeagueTableSkeleton
