import { useMemo } from 'react'

import { groupByCountry } from '../../shared/groupByCountry'
import { useFavorite } from '../stores/favorites'
import { useLeaguesFilters } from '../stores/leagues'
import { TOP_LEAGUES } from './topLeagues'
import { useAuth } from '../../auth/hooks'

export function useLeaguesData() {
  const { user, isLoading } = useAuth()
  const { data } = useLeaguesFilters()
  const { favorites } = useFavorite()
  const ids = favorites.get('league')

  const leagues = useMemo(() => {
    const sortByCountryName = data =>
      data.sort((prev, next) =>
        prev.country.name > next.country.name ? 1 : -1
      )

    return groupByCountry(sortByCountryName(data))
  }, [data.length])

  const signed = !isLoading && user

  return {
    leagues,
    popular: (signed ? [...ids] : [...TOP_LEAGUES])
      .map(idOrSlug =>
        data.find(l => l._id === idOrSlug || l.slug === idOrSlug)
      )
      .filter(Boolean)
  }
}
