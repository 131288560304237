import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTranslation } from '../../../utils/i18n'

import getFormatedTime from '../../shared/getFormatedTime'

export function useStatus({ timestamp, status }, { isLessThen860 }) {
  const { locale } = useRouter()
  const { t } = useTranslation('home', { keyPrefix: 'table.match_status' })

  return useMemo(() => {
    let { short } = status
    let statusString

    switch (short) {
      case 'HT':
        statusString = isLessThen860 ? t('ht') : t('halftime')
        break
      case 'P':
        statusString = isLessThen860 ? t('p') : t('penalty')
        break

      case 'FT':
        statusString = isLessThen860 ? t('ft') : t('finished')
        break

      case 'BT':
        statusString = isLessThen860 ? t('bt') : t('break_time')
        break

      default: {
        const { time } = getFormatedTime(timestamp * 1000, locale)

        statusString = time
        break
      }
    }

    return statusString
  }, [status.short])
}
