const sizes = [100, 80, 120, 90, 130, 100, 95, 105, 90, 75]

export default function getRandomSizes(numberOfSizes) {
  const result = []

  for (
    let sizeIndex = 0, numberOfIterations = 0;
    numberOfIterations < numberOfSizes;
    sizeIndex++, numberOfIterations++
  ) {
    if (sizeIndex > sizes.length - 1) sizeIndex = 0

    result.push(sizes[sizeIndex])
  }

  return result
}
