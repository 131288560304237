import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from '../../../utils/i18n'
import classNames from './winningRatio.module.css'
import { useQuery } from 'react-query'
import { api } from '../../../utils/api'

async function fetchData(params) {
  const data = await api('fixtures/team-form', {
    searchParams: params
  }).json()

  return data
}

const WEIGHT_POINTS = {
  L: 0,
  D: 0.5,
  W: 1
}

const last = 6

const LIGHT_COLORS = {
  'var(--sm-primary-color)': '#2EB8A5',
  'var(--sm-color-orange)': '#EF9F42',
  'var(--sm-color-red)': '#D82E4D'
}

const styleOfEmpty = {
  backgroundColor: '#DCDCDC',
  color: 'var(--sm-text-color)',
  fontSize: 13
}

export function TeamForm({ value, variant, isFetched }) {
  return (
    <div
      className={`${classNames.smallForm} ${
        isFetched && classNames['form--loaded']
      }`}
      style={
        variant === 'empty'
          ? styleOfEmpty
          : { backgroundColor: LIGHT_COLORS[variant] }
      }
    >
      {value}
    </div>
  )
}

export function WinningRatio({
  teamId: team,
  leagueId: league,
  season,
  children,
  inView,
  small,
  type
}) {
  const { t } = useTranslation('common', { keyPrefix: 'teams' })
  const { data: matches = [], isFetched } = useQuery(
    ['team-form', { team, league, season, last, type }],
    () => fetchData({ team, league, season, last, type }),
    {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['isFetched'],
      enabled: inView
    }
  )

  const scores = matches.reduce(
    (points, matchResult) => points + WEIGHT_POINTS[matchResult.result],
    0
  )

  const value = Math.round((scores / matches.length) * 100)
  const hasValue = !isNaN(value)
  const percentageOfWins = hasValue ? value : 0

  const color = hasValue
    ? percentageOfWins >= 60
      ? 'var(--sm-primary-color)'
      : percentageOfWins >= 40
      ? 'var(--sm-color-orange)'
      : 'var(--sm-color-red)'
    : 'empty'

  if (small)
    return (
      <TeamForm
        value={hasValue ? `${percentageOfWins}%` : '-'}
        variant={color}
        isFetched={isFetched}
      />
    )
  return (
    <>
      <div className={classNames.childrenScale}>{children}</div>
      <div className={classNames.circularProgress}>
        {hasValue && (
          <div className={classNames.formContainer}>
            <div style={{ backgroundColor: color }} className={classNames.form}>
              {t('form')} - {percentageOfWins}%
            </div>
          </div>
        )}
        <CircularProgress
          variant="determinate"
          value={percentageOfWins}
          thickness={2}
          sx={{ color }}
          classes={{
            root: classNames.value
          }}
        />
        {hasValue && (
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={2}
            sx={{ color }}
            classes={{
              root: classNames.valueBackground
            }}
          />
        )}
      </div>
    </>
  )
}

export default WinningRatio
