import { Box } from '@mui/system'

const STYLE = {
  height: '17px',
  width: '14px',
  fontWeight: 'bold',
  color: 'var(--sm-color-light)',
  backgroundColor: 'var(--sm-color-red)',
  borderRadius: '2px',
  fontSize: 12,
  lineHeight: 1.6,
  textAlign: 'center',
  padding: '1px',
  paddingBottom: '3px'
}

export function RedCards({ number, style }) {
  if (!number) return null

  return (
    <Box
      sx={{
        ...STYLE,
        ...style
      }}
    >
      {number}
    </Box>
  )
}

export function Card({ cardType, style }) {
  const STYLE = {
    width: '14px',
    height: '20px',
    borderRadius: '2px'
  }

  const color = cardType === 'red' ? 'var(--sm-color-red)' : '#F3C945'
  return <div style={{ ...STYLE, ...style, backgroundColor: color }}></div>
}
