import { useQuery } from 'react-query'

import Link from 'next/link'

import { CacheConstants } from '../shared/constants'
import { api } from '../../utils/api'
import { ROUTES } from '../../utils/routes'
import s from './last-matches.module.css'
import { MatchStatusBox } from '../shared/MatchStatusBox'
import { useTranslation } from '../../utils/i18n'

const url = slug => `${ROUTES.match}${slug}/h2h`

async function fetchData(params) {
  const data = await api('fixtures/team-form', {
    searchParams: params
  }).json()

  return data
}

export function LastMatches({ team, league, season, inView, type = 'all' }) {
  const { data: matches = [], isFetched } = useQuery(
    ['team-form', { team, league, season, type }],
    () => fetchData({ team, league, season, type }),
    {
      notifyOnChangeProps: ['isFetched'],
      staleTime: CacheConstants.ONE_HOUR,
      refetchOnWindowFocus: false,
      enabled: inView
    }
  )

  const { t } = useTranslation('common', { keyPrefix: 'result' })

  return (
    <div
      className={`${s['last-matches-container']} ${
        isFetched && s['last-matches-container--loaded']
      }`}
    >
      {matches.map(fixture => (
        <Link
          href={url(fixture.slug)}
          key={fixture.slug}
          passHref
          prefetch={false}
        >
          <MatchStatusBox type={fixture.result}>
            {t(fixture.result.toLowerCase())}
          </MatchStatusBox>
        </Link>
      ))}
    </div>
  )
}
