import { Grid, Paper } from '@mui/material'
import dynamic from 'next/dynamic'
import { memo } from 'react'
import { useLeaguesData } from './countries/Provider'

const Countries = dynamic(() => import('./countries/Countries'), {
  ssr: false
})
const PopularLeagues = dynamic(() => import('./countries/PopularLeagues'), {
  ssr: false
})

function _Aside() {
  const { leagues, popular } = useLeaguesData()

  return (
    <Grid
      component="aside"
      sx={{
        display: 'flex',
        padding: '10px 0px',
        width: '100%',
        maxWidth: '350px',

        '.filterContainer': {
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100% - 20px)',
          margin: '10px'
        },

        '@media (max-width: 1365px)': {
          display: 'none'
        }
      }}
      container
      direction="column"
    >
      <Paper className={'filterContainer'}>
        <PopularLeagues data={popular} />
      </Paper>
      <Paper className={'filterContainer'}>
        <Countries data={leagues} />
      </Paper>
    </Grid>
  )
}

export const Aside = memo(_Aside, () => true)
