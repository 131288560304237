import { memo } from 'react'
import { useQuery } from 'react-query'

import classNames from './leagueTable/league-table.module.css'
import { api } from '../../utils/api'
import { CacheConstants } from '../shared/constants'

async function fetchData(fixtureId) {
  const data = await api('predictions', {
    searchParams: { fixture: fixtureId, home: true }
  }).json()

  return data
}

const STYLE = {
  margin: '0px 5px'
}

function _Predictions({ fixtureId, inView }) {
  const { data: predictions = [], isFetched } = useQuery(
    ['predictions', { fixtureId, home: true }],
    () => fetchData(fixtureId),
    {
      notifyOnChangeProps: ['isFetched'],
      staleTime: CacheConstants.ONE_HOUR,
      enabled: inView,
      keepPreviousData: true
    }
  )

  const [home, draw, away] = predictions

  return (
    <div
      className={`${classNames.predictions} ${
        isFetched && classNames['predictions--loaded']
      }`}
    >
      <span style={STYLE}>{home}</span>
      <span style={STYLE}>{draw}</span>
      <span style={STYLE}>{away}</span>
    </div>
  )
}

export const Predictions = memo(_Predictions, (prev, next) => {
  return prev.inView === false && next.inView === true ? false : true
})
