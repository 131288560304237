export default function getFormatedTime(date, locale) {
  locale = process.browser ? undefined : locale
  const fullDate = new Date(date).toLocaleTimeString(locale, {
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit'
  })

  const [_date, time] = fullDate.split(', ')

  return { date: _date, time }
}
