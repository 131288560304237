import { Skeleton } from '@mui/material'

export default function SmSkeleton(props) {
  return (
    <Skeleton
      {...props}
      variant={props.variant ?? 'rectangular'}
      style={{
        borderRadius: props.variant === 'circular' ? '100%' : 4,
        ...props.style
      }}
    />
  )
}
