import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import StarIcon from '@mui/icons-material/Star'
import { Paper } from '@mui/material'
import { useRouter } from 'next/router'
import { useTranslation } from '../../../utils/i18n'
import { memo, useEffect, useState } from 'react'

import { useFavorite } from '../stores/favorites'
import { isCurrentDate, isFutureDate, isPastDate } from '../../../utils/dates'

export const TABS = {
  ALL: 0,
  LIVE: 1,
  FINISHED: 2,
  SCHEDULED: 3,
  FAVORITE: 4
}

const empty = <div></div>

function _TabFilters({ selectedDate }) {
  const { t } = useTranslation('home', { keyPrefix: 'tabs' })
  const { query, push } = useRouter()

  const tabValue = isNaN(query.tab) ? TABS.LIVE : +query.tab
  const [localQuery, setState] = useState(tabValue)
  const _isCurrentDate = isCurrentDate(selectedDate)
  const isPast = isPastDate(selectedDate)
  const isFuture = isFutureDate(selectedDate)
  const { favorites } = useFavorite()

  useEffect(() => {
    if (tabValue !== localQuery) setState(tabValue)
  }, [tabValue])

  function onChange(_, value) {
    if (value === TABS.FAVORITE && tabValue === TABS.FAVORITE) return
    setState(value)
  }

  function changeRouteQuery(value) {
    // eslint-disable-next-line no-unused-vars
    const { slug, ...rest } = query

    if (+value === +rest.tab) return

    push({
      pathname: '',
      query: {
        ...rest,
        tab: value
      }
    })
  }

  const size = favorites.get('fixture').size

  const count = size ? ` (${size > 99 ? '99+' : size})` : empty

  return (
    <Paper sx={{ width: '100%' }}>
      <Tabs
        variant="scrollable"
        scrollButtons
        value={localQuery}
        onChange={onChange}
        sx={{
          '.MuiTab-wrapper': {
            display: 'flex',
            flexDirection: 'row'
          },

          '.MuiTab-root': {
            display: 'flex',
            flexGrow: 1,
            minHeight: '64px',
            height: '64px'
          },

          '@media (max-width: 1280px)': {
            '.MuiTab-root': {
              minWidth: 0
            }
          }
        }}
        indicatorColor="primary"
        TabIndicatorProps={{
          onTransitionEnd() {
            changeRouteQuery(localQuery)
          }
        }}
        allowScrollButtonsMobile
      >
        <Tab
          label={t('all')}
          data-test-tab={t('all')}
          disabled={!_isCurrentDate}
        />
        <Tab label={t('live')} disabled={isPast || isFuture} />
        <Tab label={t('finished')} disabled={!(_isCurrentDate || isPast)} />
        <Tab label={t('scheduled')} disabled={!(isFuture || _isCurrentDate)} />
        <Tab
          sx={{
            flexDirection: 'row',
            '.MuiTab-wrapper': {
              alignItems: 'initial'
            },
            '@media (max-width: 1280px)': {
              svg: {
                margin: '0 !important'
              },
              'span:first-of-type': {
                display: 'none'
              }
            }
          }}
          icon={
            <StarIcon
              sx={{
                marginRight: '8px',
                marginBottom: '0 !important'
              }}
            />
          }
          label={
            <>
              <span style={{ marginRight: 3 }}>{t('favorite')}</span>
              <span
                style={{
                  fontFamily: 'SmartScoreNumbers, sans-serif'
                }}
              >
                {count}
              </span>
            </>
          }
        />
      </Tabs>
    </Paper>
  )
}

export const TabFilters = memo(
  _TabFilters,
  (prev, next) => prev.selectedDate === next.selectedDate
)
