import { memo } from 'react'
import { useQuery } from 'react-query'
import classNames from './leagueTable/league-table.module.css'

import { api } from '../../utils/api'

const bookmaker = 11 // 1xbet
const BET = 1 // Math winner
const ONE_DAY = 86_400_000

const TEAM_INDEX = {
  home: 0,
  draw: 1,
  away: 2
}

const WINNER_STYLE = {
  color: 'var(--sm-primary-color)',
  fontWeight: '900'
}

async function fetchData(fixtureId, bookmaker, bet) {
  const data = await api('odds', {
    searchParams: {
      fixture: fixtureId,
      bet,
      bookmaker
    }
  }).json()

  return data
}

function _Odds({ fixtureId, inView, bet = BET, winner, ...props }) {
  const { data: odds = ['-', '-', '-'], isFetched } = useQuery(
    ['odds', { fixtureId, bookmaker, bet }],
    () => fetchData(fixtureId, bookmaker, bet),
    {
      notifyOnChangeProps: ['isFetched'],
      // TODO: check if is live
      staleTime: ONE_DAY,
      enabled: inView,
      keepPreviousData: true
    }
  )

  return (
    <div
      className={`${classNames.odds} ${
        isFetched && classNames['odds--loaded']
      } ${props.className}`}
    >
      {odds.map((odd, index) => (
        <span
          style={{
            margin: '0px 3px',
            ...(index === TEAM_INDEX[winner] ? WINNER_STYLE : undefined)
          }}
          key={index}
        >
          {odd}
        </span>
      ))}
    </div>
  )
}

export const Odds = memo(_Odds, (prev, next) => {
  return prev.inView === false && next.inView === true ? false : true
})
