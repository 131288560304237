import Link from 'next/link'
import { IconButton } from '@mui/material'
import { memo } from 'react'
import Star from '@mui/icons-material/Star'

import { buildFlagUrl } from '../../../utils/cloudinaryUrl'
import classNames from './tableHeader.module.css'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { useTranslation } from '../../../utils/i18n'
import WorldIcon from '../../../../public/static/img/world.png'
import { useFavorite } from '../../filters/stores/favorites'

function TableHeader({ league, leagueUrl }) {
  const { slug, _id, name, country } = league
  const { setFavorite, isFavorite } = useFavorite({ id: _id, slug }, 'league')

  const { t } = useTranslation('common')
  const isNotWorld = !!league.country.code

  let title = t(`countries.${country.name.toLowerCase()}`) || country.name

  title = `${title}: ${name}`

  return (
    <h2
      className={classNames.header}
      style={{ backgroundColor: isFavorite ? '#FFF5D8' : '' }}
    >
      <IconButton
        onClick={setFavorite}
        size="medium"
        className={classNames.favorites}
      >
        <Star
          color={isFavorite ? 'favorite' : 'action'}
          sx={{
            transition: 'color ease 0.3s',
            '&:hover': {
              color: theme => theme.palette.favorite.main
            }
          }}
        />
      </IconButton>
      <img
        src={isNotWorld ? buildFlagUrl(country.code) : WorldIcon.src}
        alt={country.name}
        width={30}
        className={classNames[isNotWorld ? 'match-icon' : 'match-icon--world']}
        loading="lazy"
      />
      <Link href={leagueUrl} passHref prefetch={false}>
        <a className={classNames['match-table-title']}>{title}</a>
      </Link>
      <Link href={leagueUrl} passHref prefetch={false}>
        <a className={classNames['standings-link']}>
          <FormatListNumberedIcon
            sx={{ color: 'var(--sm-color-secondary)', mr: 0.5 }}
          />
          <span className={classNames.standings}>
            {t('standings.table.standings')}
          </span>
        </a>
      </Link>
    </h2>
  )
}

export default memo(TableHeader, () => true)
