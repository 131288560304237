import classNames from './teamCell.module.css'
import Link from 'next/link'
import { buildTeamAndLeagueUrl } from '../../utils/cloudinaryUrl'
import { RedCards } from './Cards'

export const teamUrl = url => {
  const x1 = buildTeamAndLeagueUrl(url, 32, 'auto')
  const x2 = buildTeamAndLeagueUrl(url, 48, 'auto')

  return {
    width: 23,
    height: 23,
    loading: 'lazy',
    style: {
      objectFit: 'contain'
    },
    src: x2,
    srcSet: `${x1} 1x, ${x2} 2x`
  }
}

function Team({
  goals,
  team,
  isFinished,
  isLive,
  url,
  events,
  hideScore,
  isWinner,
  mainTeamName
}) {
  return (
    <div className={classNames.team}>
      <Link href={url} prefetch={false}>
        <a
          className={`${classNames['team-container']} ${
            team.name === mainTeamName ? classNames.mainTeam : ''
          }`}
          style={{
            fontWeight: isFinished && isWinner ? 500 : 'inherit'
          }}
        >
          <img {...teamUrl(team.logo)} alt={team.name} />
          <span className={classNames.teamName}>{team.name}</span>
          <RedCards number={events} style={{ marginLeft: '5px' }} />
        </a>
      </Link>
      {!hideScore && (
        <span
          className={`${classNames['team-score-small']} ${
            isLive && classNames['team-score-small--live']
          }`}
        >
          {goals}
        </span>
      )}
    </div>
  )
}

function TeamCell({
  match,
  isLive,
  isFinished,
  homeUrl,
  awayUrl,
  homeTeamEvents,
  awayTeamEvents,
  hideScore,
  className,
  mainTeamName
}) {
  const { teams, goals } = match

  return (
    <div className={`${classNames['teams-small']} ${className}`}>
      <Team
        goals={goals.home}
        team={teams.home}
        isFinished={isFinished}
        isLive={isLive}
        url={homeUrl}
        events={homeTeamEvents}
        hideScore={hideScore}
        isWinner={goals.home > goals.away}
        mainTeamName={mainTeamName}
      />
      <Team
        goals={goals.away}
        team={teams.away}
        isFinished={isFinished}
        isLive={isLive}
        url={awayUrl}
        events={awayTeamEvents}
        hideScore={hideScore}
        isWinner={goals.home < goals.away}
        mainTeamName={mainTeamName}
      />
    </div>
  )
}

export default TeamCell
