import { Card, Typography } from '@mui/material'
import { useQuery } from 'react-query'

import { useTranslation } from '../../utils/i18n'
import { api } from '../../utils/api'
import LeagueTable from './leagueTable/LeagueTable'
import { useFavorite } from '../filters/stores/favorites'
import { groupByLeague, moveFavLeaguesToTop } from './index'

async function fetchData(filters) {
  const data = await api('fixtures', {
    searchParams: filters
  }).json()

  return data
}

export function FeatureFixtures({ selectedDate }) {
  const { t } = useTranslation('home')
  const { favorites } = useFavorite()

  const { data = [] } = useQuery(['fixtures', selectedDate], () =>
    fetchData({
      date: selectedDate
    })
  )

  const scheduled = data.filter(fixture => {
    const date = fixture.fixture.timestamp * 1000
    const HOUR = 3_600_000

    return date > Date.now() && date <= Date.now() + HOUR
  })

  let commingSoon = moveFavLeaguesToTop(favorites.get('league'), scheduled)
  commingSoon = groupByLeague(commingSoon)

  return commingSoon.length ? (
    <>
      <Card sx={{ p: 2, mt: 2 }}>
        <Typography
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {t('coming_soon')}
        </Typography>
      </Card>
      {commingSoon.map(fixtures => (
        <LeagueTable fixtures={fixtures} key={fixtures[0].league._id} />
      ))}
    </>
  ) : null
}
