import { Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from '../../utils/i18n'

import { useAuth } from '../auth/hooks'
import { TABS } from '../filters/tabs/tabs'
import { SingInButton } from '../shared/SignInBtn'

export function EmptyState({ selectedDate, tab }) {
  const { user, isLoading } = useAuth()
  const { t } = useTranslation('home', { keyPrefix: 'empty_state' })

  const map = {
    [TABS.LIVE]() {
      return t('live_tab')
    },
    [TABS.FAVORITE]() {
      if (!isLoading) {
        return user ? (
          t('favorite_user')
        ) : (
          <>
            {t('favorite_anonim')}
            <SingInButton />
          </>
        )
      }

      return t('loading')
    },
    [TABS.LEAGUE]() {
      return t('leagues')
    }
  }

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Typography>
          {map[tab]?.() ?? `${t('generic')} ${selectedDate}`}
        </Typography>
      </CardContent>
    </Card>
  )
}
