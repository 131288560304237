import { Fade, Paper } from '@mui/material'
import { useInView } from 'react-intersection-observer'
import { memo } from 'react'
import { useTranslation } from '../../../utils/i18n'

import { Odds } from '../Odds'
import { ROUTES } from '../../../utils/routes'
import s from './league-table.module.css'
import { FavoriteMatch } from '../FavoriteMatch'
import { Predictions } from '../Predictions'
import { LastMatches } from '../LastMatches'
import { useMatchMedia } from '../../../utils/useMatchMedia'
import { isLiveMatch, isFinishedMatch } from '../../shared/liveCheck'
import { useEvents } from './useEvents'
import TableHeader from './TableHeader'
import TeamCell from '../../shared/TeamCell'
import classNames from '../../shared/teamCell.module.css'

import ViewTip from '../../shared/viewTip/ViewTip'
import { useStatus } from './useStatus'
import WinningRatio from '../../shared/WinningRatio/WinningRatio'

const STATUSES = new Set(['HT', 'P', 'BT'])

const Time = ({ isLive, status, timestamp, isLessThen860 }) => {
  const status2 = useStatus(
    {
      status,
      timestamp
    },
    {
      isLessThen860
    }
  )

  return (
    <div className={s.time}>
      <time
        dateTime={new Date(timestamp * 1000).toISOString()}
        className={isLive ? s.elapsed : undefined}
        style={{
          textDecorationLine:
            status.short === 'PST' ? 'line-through' : undefined
        }}
      >
        {isLive && !STATUSES.has(status.short) ? status.elapsed : status2}
      </time>
    </div>
  )
}

const MatchRow = memo(function MatchRow({
  match,
  showOdds,
  showForm,
  showLast5,
  showPredictions,
  inView,
  isLessThen860
}) {
  const { t } = useTranslation('home', { keyPrefix: 'table' })
  const { home, away } = match.teams
  const url = `${ROUTES.match}${match.slug}/predictions`
  const { short } = match.fixture.status
  const isLive = isLiveMatch(short)
  const isFinished = isFinishedMatch(short)
  const urlHomeTeam = `${ROUTES.team}${home.slug}/${match.league.slug}`
  const urlAwayTeam = `${ROUTES.team}${away.slug}/${match.league.slug}`

  const { homeTeamEvents, awayTeamEvents } = useEvents(
    home._id,
    away._id,
    match.events,
    'Red Card'
  )

  const winner =
    isFinished &&
    (match.goals.home === match.goals.away
      ? 'draw'
      : match.goals.home > match.goals.away
      ? 'home'
      : 'away')

  const HomeForm = (
    <WinningRatio
      teamId={home._id}
      leagueId={match.league._id}
      season={match.season}
      inView={inView}
      type="home"
      small
    />
  )

  const AwayForm = (
    <WinningRatio
      teamId={away._id}
      leagueId={match.league._id}
      season={match.season}
      inView={inView}
      type="away"
      small
    />
  )

  return (
    <div className={`${s.row} ${s.matchRow}`}>
      <div className={s.favorite}>
        <FavoriteMatch matchId={match._id} slug={match.slug} />
      </div>
      <Time
        isLessThen860={isLessThen860}
        status={match.fixture.status}
        timestamp={match.fixture.timestamp}
        isLive={isLive}
      />
      <TeamCell
        match={match}
        awayUrl={urlAwayTeam}
        homeUrl={urlHomeTeam}
        homeTeamEvents={homeTeamEvents}
        awayTeamEvents={awayTeamEvents}
        isLive={isLive}
        isFinished={isFinished}
        className={s.teams}
        hideScore
      />
      <div className={s.score}>
        <span
          className={`${classNames['team-score-small']} ${
            isLive && classNames['team-score-small--live']
          }`}
        >
          {match.goals.home}
        </span>
        <span
          className={`${classNames['team-score-small']} ${
            isLive && classNames['team-score-small--live']
          }`}
        >
          {match.goals.away}
        </span>
      </div>
      {showForm && (
        <div className={s.form}>
          {HomeForm}
          {AwayForm}
        </div>
      )}
      {showLast5 && (
        <div className={s.last5}>
          <div className={s.last5Container}>
            <LastMatches team={away._id} inView={inView} />
          </div>
          <div className={s.last5Container}>
            <LastMatches team={home._id} inView={inView} />
          </div>
        </div>
      )}
      {showOdds && (
        <Odds
          fixtureId={match._id}
          inView={inView}
          winner={winner}
          className={s.oddsValues}
        />
      )}
      {showPredictions && <Predictions fixtureId={match._id} inView={inView} />}

      <div className={s.tip}>
        <ViewTip url={url} isLive={isLive} label={t('header.view_tip')} />
      </div>
    </div>
  )
})

const bet = <span className={s.betLabel}>&nbsp;(1x2)</span>

const TableHeaderRow = memo(function TableHeaderRow({
  showOdds,
  showForm,
  showLast5,
  showPredictions
}) {
  const { t } = useTranslation('home', { keyPrefix: 'table.header' })
  return (
    <div className={s.row}>
      <div className={s.favorite}></div>
      <div className={s.time}>{t('time')}</div>
      <div className={s.teams} style={{ justifyContent: 'flex-start' }}>
        {t('home')}/{t('away')}
      </div>
      <div className={s.score}></div>
      {showForm && <div className={s.form}>{t('form')}</div>}

      {showLast5 && <div className={s.last5}>{t('last5')}</div>}
      {showOdds && (
        <div className={`${s.odds} ${s['odds--loaded']}`}>
          {t('odds')}
          {bet}
        </div>
      )}
      {showPredictions && (
        <div className={`${s.predictions} ${s['predictions--loaded']}`}>
          {t('predictions')} {bet}
        </div>
      )}
      <div className={s.tip}>{t('tip')}</div>
    </div>
  )
})

export default function LeagueTable({ fixtures }) {
  const showOdds = useMatchMedia('(min-width: 360px)')
  const showForm = useMatchMedia('(min-width: 360px)')
  const showLast5 = useMatchMedia('(min-width: 680px)')
  const isLessThen860 = useMatchMedia('(max-width: 860px)')
  const showPredictions = useMatchMedia('(min-width: 767px)')

  const { ref, inView } = useInView({ triggerOnce: true })

  const { league } = fixtures[0]

  const leagueUrl = `${ROUTES.league}${league.slug}/standings`

  return (
    <Fade in timeout={1000}>
      <Paper className={s.table} ref={ref}>
        <TableHeader league={league} leagueUrl={leagueUrl} />
        <TableHeaderRow
          showOdds={showOdds}
          showForm={showForm}
          showLast5={showLast5}
          showPredictions={showPredictions}
        />
        {fixtures.map(match => (
          <MatchRow
            key={match._id}
            match={match}
            showOdds={showOdds}
            showForm={showForm}
            showLast5={showLast5}
            showPredictions={showPredictions}
            inView={inView}
            isLessThen860={isLessThen860}
          />
        ))}
      </Paper>
    </Fade>
  )
}
