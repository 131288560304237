const live = new Set(['1H', 'HT', '2H', 'ET', 'P', 'BT'])
const willBe = new Set(['TBD', 'NS', 'SUSP', 'INT', 'PST', 'WO'])
const finished = new Set(['FT', 'AET', 'PEN', 'CANC', 'ABD', 'AWD'])

export function isLiveMatch(short) {
  return live.has(short)
}

export function isFutureMatch(short) {
  return willBe.has(short)
}

export function isFinishedMatch(short) {
  return finished.has(short)
}

// TBD : Time To Be Defined +
// NS : Not Started +
// 1H : First Half, Kick Off +
// HT : Halftime +
// 2H : Second Half, 2nd Half Started +
// ET : Extra Time +
// P : Penalty In Progress +
// FT : Match Finished -
// AET : Match Finished After Extra Time -
// PEN : Match Finished After Penalty -
// BT : Break Time (in Extra Time)
// SUSP : Match Suspended +
// INT : Match Interrupted +
// PST : Match Postponed +
// CANC : Match Cancelled -
// ABD : Match Abandoned -
// AWD : Technical Loss -
// WO : WalkOver +
// LIVE : In Progress *
