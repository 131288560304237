import classNames from './teamCell.module.css'
import SmSkeleton from '../Skeleton'

function TeamCell({ homeTeamSize, awayTeamSize, className }) {
  return (
    <div className={`${classNames.teams} ${className}`}>
      <div style={{ display: 'flex', width: '100%', marginBottom: 8 }}>
        <SmSkeleton className={classNames.teamLogo} />
        <SmSkeleton
          className={classNames.teamName}
          style={{ maxWidth: homeTeamSize }}
        />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <SmSkeleton className={classNames.teamLogo} />
        <SmSkeleton
          className={classNames.teamName}
          style={{ maxWidth: awayTeamSize }}
        />
      </div>
    </div>
  )
}

export default TeamCell
