import { useInView } from 'react-intersection-observer'

export const NextRender = ({ next }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    onChange(inView) {
      if (inView) {
        next()
      }
    }
  })

  return !inView && <div ref={ref} />
}
