import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { default as Page } from '../components/home'

/**
 * @typedef {import('next-i18next/dist/types/types').SSRConfig} SSRConfig
 * @typedef {{ [key: string]: any } & SSRConfig} Props
 * @param {import('next/types').GetServerSidePropsContext}
 * @returns {import('next/types').GetServerSidePropsResult<Props>}
 */
export async function getStaticProps({ locale }) {
  const tr = await serverSideTranslations(locale, ['home', 'common'])

  return {
    props: {
      ...tr
    }
  }
}

export default Page
