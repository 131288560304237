import SmSkeleton from '../Skeleton'

function LastMatches() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <SmSkeleton width={22} height={22} />
      <SmSkeleton width={22} height={22} style={{ marginLeft: 8 }} />
      <SmSkeleton width={22} height={22} style={{ marginLeft: 8 }} />
      <SmSkeleton width={22} height={22} style={{ marginLeft: 8 }} />
      <SmSkeleton width={22} height={22} style={{ marginLeft: 8 }} />
    </div>
  )
}

export default LastMatches
