import { useState, useEffect, useRef } from 'react'

export function useMatchMedia(media) {
  const ref = useRef(matchMedia(media))
  const [matches, setMatch] = useState(ref.current.matches)

  useEffect(() => {
    const listener = e => setMatch(e.matches)
    ref.current.addEventListener('change', listener)

    return () => ref.current.removeEventListener('change', listener)
  }, [])

  return matches
}
