import Link from 'next/link'
import { Button } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from '../../../utils/i18n'

import classNames from './viewTip.module.css'

function ViewTip({ url, label, isLive }) {
  const { t } = useTranslation('common')

  return (
    <Link href={url} passHref prefetch={false}>
      <Button
        variant="outlined"
        title={t('view_tip_title')}
        className={`${classNames.cta} ${isLive ? classNames.isLive : ''}`}
      >
        <VisibilityIcon className={classNames.icon} />
        <span className={classNames.label}>{label}</span>
      </Button>
    </Link>
  )
}

export default ViewTip
