import isToday from 'date-fns/isToday'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'

export function isCurrentDate(selectedDate) {
  return isToday(new Date(selectedDate))
}

export function isFutureDate(selectedDate) {
  return isFuture(new Date(selectedDate)) && !isCurrentDate(selectedDate)
}

export function isPastDate(selectedDate) {
  return isPast(new Date(selectedDate)) && !isCurrentDate(selectedDate)
}
