import { Grid } from '@mui/material'
import { useQuery } from 'react-query'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useEffect, useState, Fragment } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from '../../utils/i18n'

import LeagueTable from './leagueTable/LeagueTable'
import { EmptyState } from './EmptyState'
import { Aside } from '../filters/aside'
import { TabFilters, TABS } from '../filters/tabs/tabs'
import { api } from '../../utils/api'
import { CacheConstants } from '../shared/constants'
import { useLeaguesFilters } from '../filters/stores/leagues'
import { useOddsFilter } from '../filters/stores/odds'
import { useFavorite } from '../filters/stores/favorites'
import { isCurrentDate } from '../../utils/dates'
import { Dates, TODAY_SELECTED } from '../filters/dates/dates'
import LeagueTableSkeleton from '../shared/skeleton/leagueTableSkeleton/LeagueTableSkeleton'
import { NextRender } from '../shared/NextRender'
import { useFormsFilter } from '../filters/stores/form'
import { FeatureFixtures } from './FeatureFixtures'
// import { UserSurvey } from './UserSurvey'
import { useAuth } from '../auth/hooks'

// const ProviderIssue = dynamic(() => import('./ProviderIssueAlert'), {
//   ssr: false
// })

const SKELETON = Array.from({ length: 5 }, (_, index) => (
  <LeagueTableSkeleton key={index} />
))

const FabFilter = dynamic(() => import('../filters/FabFilter/FabFilter'), {
  ssr: false
})

const Chips = dynamic(() => import('../filters/chips/Chips'), {
  ssr: false
})

const SCHEDULED_STATUSES = new Set(['NS', 'PST', 'TBD'])
function getScheduled(fixtures) {
  return fixtures.filter(fixture =>
    SCHEDULED_STATUSES.has(fixture.fixture.status.short)
  )
}

export function groupByLeague(fixturesByDate) {
  const map = new Map()

  for (const fixture of fixturesByDate) {
    const leagueFixtures = map.get(fixture.league._id)

    if (leagueFixtures) {
      leagueFixtures.push(fixture)
      map.set(fixture.league._id, leagueFixtures)
    } else {
      map.set(fixture.league._id, [fixture])
    }
  }

  const array = [...map.values()]

  return array
}

async function fetchData(
  filters = {
    live: 'all'
  }
) {
  console.log(filters)
  const data = await api('fixtures', {
    searchParams: filters
  }).json()

  return data
}

const move = (from, to, ...array) => {
  array.splice(to, 0, ...array.splice(from, 1))

  return array
}

export function moveFavLeaguesToTop(favoritesLeagues, data) {
  if (favoritesLeagues.size) {
    let newIndex = 0

    for (const id of favoritesLeagues) {
      const fromIndex = data.findIndex(fixture => fixture.league._id === id)

      if (fromIndex !== -1) {
        data = move(fromIndex, newIndex, ...data)

        newIndex++
      }
    }
  }

  return data
}

const INITIAL_AMOUNT_COUNT = 3

function Home() {
  const { user } = useAuth()
  console.log({ user })
  const { favorites } = useFavorite()
  const { leagues } = useLeaguesFilters()
  const [odds] = useOddsFilter()
  const [form] = useFormsFilter()
  const [amount, renderNextAmount] = useState(INITIAL_AMOUNT_COUNT)

  const { query } = useRouter()
  const selectedTab = Number(query.tab ?? TABS.LIVE)
  const selectedDate = query.date ?? TODAY_SELECTED

  const isLiveTab = selectedTab === TABS.LIVE
  const isFavorite = selectedTab === TABS.FAVORITE
  const _isCurrentDate = isCurrentDate(selectedDate)
  const favoritesSet = favorites.get('fixture')
  const favoritesKey = isFavorite && favoritesSet.size > 0

  const favoritesLeagues = favorites.get('league')

  const oddsHomeKey = odds.home.isDefault ? undefined : odds.home.value
  const oddsAwayKey = odds.away.isDefault ? undefined : odds.away.value
  const oddsParams = {
    oddsHome: odds.home.isDefault ? undefined : odds.home.value,
    oddsAway: odds.away.isDefault ? undefined : odds.away.value
  }

  const formHomeKey = form.home.isDefault ? undefined : form.home.value
  const formAwayKey = form.away.isDefault ? undefined : form.away.value
  const formParams = {
    formHome: form.home.isDefault ? undefined : form.home.value,
    formAway: form.away.isDefault ? undefined : form.away.value
  }

  const {
    data: store = [],
    isFetched,
    isRefetching,
    isFetching
  } = useQuery(
    [
      'fixtures',
      selectedDate,
      oddsHomeKey,
      oddsAwayKey,
      formHomeKey,
      formAwayKey,
      favoritesKey,
      selectedTab,
      leagues
    ],
    () =>
      fetchData({
        leagues: leagues.length ? leagues : undefined,
        date: isLiveTab ? undefined : selectedDate,
        ids: isFavorite && favoritesSet.size ? [...favoritesSet] : undefined,
        oddsHome: odds.home.value,
        oddsAway: odds.away.value,
        ...oddsParams,
        ...formParams,
        ...(isLiveTab && { live: 'all' }),
        cache: 1
      }),
    {
      notifyOnChangeProps: ['isFetched', 'data', 'isFetching', 'isRefetching'],
      refetchInterval: _isCurrentDate ? CacheConstants.TEN_SECONDS : undefined,
      retry: !isFavorite,
      staleTime: CacheConstants.TEN_SECONDS,
      onSuccess(data) {
        const main = []
        const world = []
        const england = []
        const italy = []
        const spain = []
        const germany = []
        const france = []
        const rest = []

        for (const fixture of data) {
          switch (fixture.league.country.name) {
            case 'World':
              world.push(fixture)
              break
            case 'England':
              england.push(fixture)
              break
            case 'Italy':
              italy.push(fixture)
              break

            case 'Spain':
              spain.push(fixture)
              break

            case 'Germany':
              germany.push(fixture)
              break

            case 'France':
              france.push(fixture)
              break
            default:
              rest.push(fixture)
              break
          }
        }

        const sorted = main.concat(
          world,
          england,
          italy,
          spain,
          germany,
          france,
          rest.sort((prev, next) =>
            prev.league.country.name.localeCompare(next.league.country.name)
          )
        )

        return sorted
      }
    }
  )

  useEffect(() => {
    if (isRefetching) return

    if (isFetching && amount > INITIAL_AMOUNT_COUNT)
      renderNextAmount(INITIAL_AMOUNT_COUNT)
  }, [isFetching])

  let tables = null

  if (isFetched) {
    let data = []

    switch (selectedTab) {
      case TABS.LIVE:
        {
          const fixtures = store
          data = fixtures
        }

        break

      case TABS.FAVORITE:
        {
          const fixtures = store
          data = fixtures.filter(fixture => favoritesSet.has(fixture._id))
        }

        break

      case TABS.FINISHED: {
        const fixtures = store
        data = fixtures.filter(fixture => fixture.fixture.status.short === 'FT')
        break
      }

      case TABS.SCHEDULED: {
        const fixtures = store
        data = getScheduled(fixtures)
        break
      }

      default:
        data = store
        break
    }

    if (data.length) {
      const IS_LIVE = selectedTab === TABS.LIVE
      data = moveFavLeaguesToTop(favoritesLeagues, data)
      const grouped = groupByLeague(data)

      let result = []

      if (IS_LIVE) {
        result = grouped.map(fixtures => (
          <LeagueTable fixtures={fixtures} key={fixtures[0].league._id} />
        ))
      } else {
        result = grouped.slice(0, amount).map((fixtures, index, arr) => (
          <Fragment key={fixtures[0].league._id}>
            <LeagueTable fixtures={fixtures} />
            {index === arr.length - 3 && (
              <NextRender
                next={() => {
                  const nextAmount = Math.min(amount + 30, data.length)

                  renderNextAmount(nextAmount)
                }}
              />
            )}
          </Fragment>
        ))
      }

      const soonResult = IS_LIVE && (
        <FeatureFixtures selectedDate={selectedDate} />
      )

      tables = (
        <>
          {result}
          {/* {result.length > 0 && <UserSurvey />} */}
          {soonResult}
        </>
      )
    } else {
      tables = <EmptyState selectedDate={selectedDate} tab={selectedTab} />
    }
  } else {
    tables = SKELETON
  }

  return (
    <Grid
      container
      direction="row"
      sx={{
        width: 'auto',
        flexWrap: 'nowrap',
        '@media (max-width: 1365px)': {
          flexWrap: 'wrap',
          justifyContent: 'center'
        }
      }}
    >
      <FabFilter />
      <Aside />

      <Grid
        direction="column"
        container
        sx={{
          padding: '20px 10px',

          '@media (max-width: 1365px)': {
            padding: '0px 10px 20px'
          },
          '@media (max-width: 580px)': {
            padding: '0px 5px 20px'
          }
        }}
      >
        <Dates />
        <TabFilters selectedDate={selectedDate} />
        <Chips />
        {/* <ProviderIssue /> */}
        {tables}
      </Grid>
    </Grid>
  )
}

const HomeWithProviders = () => {
  const { t } = useTranslation('home')
  const { t: tHead } = useTranslation('common', { keyPrefix: 'head' })

  return (
    <>
      <Head>
        <title>{t('title')} | SmartScore365</title>
        <meta property="og:title" content={`${t('title')} | SmartScore365`} />
        <meta name="description" content={tHead('description')} />
        <meta property="og:description" content={tHead('description')} />
        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_BASE_API_URL}/fixtures?live=all&cache=1`}
          as="fetch"
          crossOrigin="true"
        />
      </Head>
      <Home />
    </>
  )
}

export default HomeWithProviders
