import { forwardRef } from 'react'
import classNames from './match-status-box.module.css'

export const MatchStatusBox = forwardRef(function MatchStatusBox(
  { children, type, ...props },
  ref
) {
  const Tag = props.href ? 'a' : 'span'

  return (
    <Tag
      ref={ref}
      {...props}
      className={`${classNames['match-status-type']} ${classNames[type]} ${props.className}`}
    >
      {children}
    </Tag>
  )
})
